<template>
	<v-system-bar
		v-if="isVisible"
		app
		color="#262626"
		class="px-2"
		dark
		height="20"
	>
		<v-spacer />

		<v-btn text small @click="changeLocale('bg')">български</v-btn>

		<v-btn text small @click="changeLocale('en')">english</v-btn>

		<v-btn text small @click="changeLocale('de')">deutsch</v-btn>

		<v-divider class="ml-6 mr-3" vertical />

		<v-btn
			class="text-capitalize grey--text mr-n2 mr-sm-n1"
			small
			text
			@click="isVisible = false"
		>
			<v-icon color="white" :left="$vuetify.breakpoint.smAndUp">
				mdi-close
			</v-icon>

			<span class="hidden-xs-only">
				{{ $t('close') }}
			</span>
		</v-btn>
	</v-system-bar>
</template>

<script>
export default {
	name: 'LanguageBar',

	data() {
		return {
			isVisible: true,
			locale: '',
		};
	},
	methods: {
		changeLocale(value) {
			this.locale = value;
		},
	},
	watch: {
		locale(val) {
			this.$i18n.locale = val;
			localStorage.setItem('user-language', this.$i18n.locale);
		},
	},
	created() {
		if (localStorage.getItem('user-language')) {
			this.$i18n.locale = localStorage.getItem('user-language');
		} else {
			// This should put the lang of the app depending on the user browser lang
			let userBrowserLang =
				window.navigator.language || window.navigator.userLanguage;

			// Check for en lang and drop the US, GB... etc
			if (userBrowserLang.includes('en')) {
				userBrowserLang = 'en';
			}

			this.$i18n.locale = userBrowserLang;
			// this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE;
		}
		this.locale = this.$i18n.locale;
	},
};
</script>

<style lang="scss" scoped></style>
